import React, { Suspense } from "react"
import { render } from "react-dom"

import { Provider } from "react-redux"
import { applyMiddleware, createStore } from "redux"
import rootReducer from "./state/reducers"
import thunk from "redux-thunk"

import App from "./App"

import * as serviceWorker from "./serviceWorker"

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"

import "./index.css"

const theme = createMuiTheme({
  fontFamily: "Roboto, sans-serif",
  palette: {
    primary: {
      main: "#007CC0",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#FFFFFF"
    }
  }
})

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
)

render(
  <Provider store={store}>
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <Suspense fallback=''>
          <App />
        </Suspense>
      </MuiThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
