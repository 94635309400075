import { combineReducers } from 'redux'
import * as actions from '../actions'

function appinfo(state= null, action) {
  switch (action.type) {
    case actions.SET_APP_INFO:
      return action.appinfo

    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case actions.SET_ERROR:
      return action.error

    default:
      return state
  }
}

export default combineReducers({
  appinfo,
  error
})