import { combineReducers } from 'redux'
import * as actions from '../actions'

function loading(state = 0, action) {
  switch (action.type) {
    case actions.LOADING_STATE:
      return action.loading

    default:
      return state
  }
}

export default combineReducers({
  loading
})