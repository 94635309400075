import React from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withTranslation } from "react-i18next"

// import asyncActions from "./state/async-actions"
import * as actions from "./state/actions"

import {
  Snackbar, Typography, IconButton
} from "@material-ui/core"

import {
  Facebook, Instagram, YouTube
} from "@material-ui/icons"

import Alert from "@material-ui/lab/Alert"

// Hieronder componenten importeren
import About from "./images/about.md"
import logotwh from "./images/logotwh.png"
import ReactMarkdown from "react-markdown"

import i18n from "./i18n"

import "./App.css"
import "./ImageGallery.css"
import ImageGallery from "react-image-gallery"

import image001 from "./images/gallery/img001.jpg"
import image002 from "./images/gallery/img002.jpg"
import image003 from "./images/gallery/img003.jpg"
import image004 from "./images/gallery/img004.jpg"
import image005 from "./images/gallery/img005.jpg"
import image006 from "./images/gallery/img006.jpg"
import image007 from "./images/gallery/img007.jpg"
import image008 from "./images/gallery/img008.jpg"
import image009 from "./images/gallery/img009.jpg"
// import image010 from "./images/gallery/img010.jpg"
import image011 from "./images/gallery/img011.jpg"
// import image012 from "./images/gallery/img012.jpg"
import image013 from "./images/gallery/img013.jpg"

const styles = theme => ({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  roundImage: {
    zIndex: "2",
    height: "175px"
  },
  titleText: {
    letterspacing: "3px",
    color: "#FFFFFF"
  },
  largeIcon: {
    fontSize: "2em"
  }
})

class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      currentWidth: 0,
      currentHeight: 0,
      aboutText: "",
      images: [
        {
          original: image001
        },
        {
          original: image002
        },
        {
          original: image003
        },
        {
          original: image004
        },
        {
          original: image005
        },
        {
          original: image006
        },
        {
          original: image007
        },
        {
          original: image008
        },
        {
          original: image009
        },
        // {
        //   original: image010
        // },
        {
          original: image011
        },
        // {
        //   original: image012
        // },
        {
          original: image013
        }
      ]
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)

    this.changeLanguage(this, "nl")
  }

  updateWindowDimensions = () => {
    this.setState({ currentWidth: window.innerWidth, currentHeight: window.innerHeight })
  }

  componentDidMount = () => {
    this.updateWindowDimensions()
    fetch(About).then((response) => response.text()).then((text) => {
      this.setState({ aboutText: text })
    })
  }

  changeLanguage = (that, lng) => {
    i18n.changeLanguage(lng)

    that.state.selectedLanguage = lng
    window.localStorage.selectedLanguage = lng
  }

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") return null

    this.props.dispatch({ type: actions.SET_ERROR, error: null })
  }

  render () {
    const { application, t, database, classes } = this.props

    const { aboutText, currentWidth, images } = this.state
    return (
      <div className='App' style={{ overflowY: "scroll" }}>
        {database.loading > 0 && <div className='loader-background'>
          <img src='/static/images/loader.gif' alt='loader' className='loader-default' />
        </div>}
        {application.error && <Snackbar open={!!application.error} autoHideDuration={application.error.duration} onClose={this.closeSnackbar} style={{ zIndex: 99999999 }}>
          <Alert elevation={6} variant='filled' severity={application.error.type}>
            {t(application.error.message.toString())}
          </Alert>
        </Snackbar>}
        <div className={classes.flexContainer}>
          <img className={classes.roundImage} src={logotwh} alt='The Wild Hunt' />
          <div style={{ height: "44px", borderLeft: "8px solid white", zIndex: "1", position: "relative", display: "flex", marginTop: "-8px" }} />
          <div style={{ width: "90%", borderBottom: "8px solid white", zIndex: "1", position: "relative", display: "flex", marginTop: "-8px" }} />
          <div style={{ textAlign: "center" }}>
            <Typography className={classes.titleText} variant='h3'>{t("the_wild_hunt")}</Typography>
            <Typography className={classes.titleText} variant='h4'>{t("honky_tonky")}</Typography>
            <IconButton color='secondary' aria-label='Facebook' href='https://www.facebook.com/The-Wild-Hunt-104996694540156' target='_blank'><Facebook className={classes.largeIcon} /></IconButton>
            <IconButton color='secondary' aria-label='YouTube' href='https://www.youtube.com/channel/UCHH9uNVA0JAtMjjNy6xotAA' target='_blank'><YouTube className={classes.largeIcon} /></IconButton>
            <IconButton color='secondary' aria-label='Instagram' href='https://www.instagram.com/thewildhuntband/' target='_blank'><Instagram className={classes.largeIcon} /></IconButton>

          </div>
          <div style={{ width: "90%", borderBottom: "8px solid white", zIndex: "1", position: "relative", display: "flex" }} />
          <div style={{ height: "calc(100vh - 470px)", width: "90%", overflowY: "hidden", marginTop: "8px" }}>
            <ImageGallery autoPlay lazyLoad slideDuration={0} showNav={false} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} slideInterval={5000} items={images} />
          </div>
          <div style={{ width: "90%", borderBottom: "8px solid white", zIndex: "1", position: "relative", display: "flex" }} />
          <div style={{ textAlign: "center", color: "white" }}>
            <ReactMarkdown source={aboutText} />
          </div>
          <div style={{ height: "44px", borderLeft: "8px solid white", zIndex: "1", position: "relative", display: "flex", marginTop: "-8px" }} />
          <div style={{ width: "90%", borderBottom: "8px solid white", zIndex: "1", position: "relative", display: "flex" }} />
        </div>
      </div>
    )
  }
}

App = withTranslation("translation")(App)
App = withStyles(styles, { withTheme: true })(App)
export default connect(state => state)(App)
